<template>
    <div
        class="drop-shadow-mobile-menu border-t border-brand-neutral-03 bg-white z-[100] fixed bottom-0 left-0 w-full md:w-96 md:left-1/2 md:-translate-x-1/2 p-4 flex w-screen"
    >
        <div
            v-for="(menuItem, index) in menu"
            :key="index"
            :class="{ hidden: !menuItem.visible, 'w-full': true }"
        >
            <NuxtLink
                v-if="menuItem.link"
                :to="menuItem.link"
                class="flex w-full flex-col items-center justify-between text-brand-neutral-4-75 shrink-0"
                active-class="font-bold !text-brand-primary-1"
            >
                <span :class="[menuItem.icon, 'text-2xl']"></span>
                <span class="text-xs">{{ menuItem.title }}</span>
            </NuxtLink>
            <div
                class="w-[78px] h-full relative shrink-0"
                v-if="menuItem.scanButton && menuItem.visible"
            >
                <Button
                    class="absolute rounded-full flex flex-col !p-0 w-[78px] h-[78px] bottom-0 gap-0"
                    @click="openScanner"
                >
                    <span class="fi fi-sr-qr-scan text-2xl"></span>
                    <span class="text-xs font-normal">Scanear</span>
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
const userStore = useUserStore();
const { openScanner } = useQRCodeScanner();
const { user } = storeToRefs(userStore);

const menu = computed(() => [
    {
        title: "Home",
        link: "/account",
        icon: "fi fi-sr-house-blank",
        visible: true,
    },
    {
        title: "QR Code",
        link: "/account/wallet",
        icon: "fi fi-sr-wallet",
        visible: true,
    },
    {
        scanButton: true,
        visible: user.value?.isApplicationOwner,
    },
    {
        title: "Explorar",
        link: "/applications",
        icon: "fi fi-sr-badge-percent",
        visible: true,
    },
    {
        title: "Menu",
        link: "/account/menu",
        icon: "fi fi-br-menu-burger",
        visible: true,
    },
]);
</script>
